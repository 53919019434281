import React, {useRef} from "react";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { TextPlugin } from "gsap/TextPlugin";

import splitType from 'split-type'

import './style/main.css';
import './style/responsive.css'
import Cursor from "./components/Cursor";
import MobileMenu from "./components/MobileMenu";
import Hero from "./components/Hero";

function App() {
    const cursorRef = useRef<HTMLDivElement>(null);

    return (
    <>
        <Cursor cursorRef={cursorRef} />
        <MobileMenu />

        <div className="body--container">
            <Hero cursorRef={cursorRef} />
        </div>
    </>
    );
}

export default App;
